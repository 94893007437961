<template>
  <div>
    <skeleton-inventory-component v-if="bSkeleton" />

    <div class="content-base-price-po-so" v-else>
      <!-- <p class="p-text-title ">Precio base</p> -->
      <div class="content-base-price-raw-po">
        <div class="content-title-text-all">
          <p class="p-text-title">Precio base</p>
          <div class="content-title-text-purchase-mxn mb-2">
            <p class="p-title">Precio base actual de COMPRA</p>
            <p class="p-text">
              $ {{ formatMillon(dBasePurchasePriceMXN) }} MXN
            </p>
          </div>

          <div class="content-title-text-purchase-usd">
            <p class="p-title">Precio base actual de COMPRA</p>
            <p class="p-text">
              $ {{ formatMillon(dBasePurchasePriceUSD) }} USD
            </p>
          </div>
        </div>
        <div class="content-btns-actions">
          <div class="content-btn-new-base-price">
            <v-btn
              class="global-btn-primary"
              v-show="bAdminMaterial"
              @click="setDialogNewBasePrice(false)"
            >
              Actualizar precio base
            </v-btn>
          </div>
        </div>
      </div>
      <v-divider vertical class="divider-global"></v-divider>

      <div class="content-base-price-raw-so">
        <div class="content-title-text-all">
          <br/>
          <div class="content-title-text-sales-mxn mb-2">
            <p class="p-title">Precio base actual de VENTA</p>
            <p class="p-text">$ {{ formatMillon(dBaseSalePriceMXN) }} MXN</p>
          </div>
          <div class="content-title-text-sales-usd">
            <p class="p-title">Precio base actual de VENTA</p>
            <p class="p-text">$ {{ formatMillon(dBaseSalePriceUSD) }} USD</p>
          </div>
        </div>
        <div class="content-btns-actions">
          <div class="content-btn-new-base-price">
            <v-btn
              class="global-btn-primary"
              v-show="bAdminMaterial"
              @click="setDialogNewBasePrice(true)"
            >
              Actualizar precio base
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-divider class="divider-global"></v-divider>
    <dialog-new-base-price-component
      :iCurrentAmount="iCurrentAmount"
      :bDialogNewBasePrice="bDialogNewBasePrice"
      :dBasePurchasePriceMXN="dBasePurchasePriceMXN"
      :dBasePurchasePriceUSD="dBasePurchasePriceUSD"
      :dBaseSalePriceMXN="dBaseSalePriceMXN"
      :dBaseSalePriceUSD="dBaseSalePriceUSD"
      :bPurchaseOrSale="bPurchaseOrSale"
      @setDialogNewBasePrice="setDialogNewBasePrice"
      :sLabelBasePrice="sLabelBasePrice"
      sLabelCurrentCustomPrice=""
    />
  </div>
</template>
<script>
export default {
  props: {
    dBasePurchasePriceMXN: Number,
    dBasePurchasePriceUSD: Number,
    dBaseSalePriceMXN: Number,
    dBaseSalePriceUSD: Number,
  },
  data() {
    return {
      bDialogNewBasePrice: false,
      oBasePrice: {},
      bSkeleton: true,
      iCurrentAmount: 0,
      options: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      bPurchaseOrSale: false, //false is purchase, true is sale
      sLabelBasePrice: "",
    };
  },
  beforeMount() {
    this.getBasePriceById();
  },
  methods: {
    setDialogNewBasePrice(bPurchaseOrSale) {
      this.sLabelBasePrice = bPurchaseOrSale
        ? "Precio base actual de materia: Venta"
        : "Precio base actual de materia: Compra";
      this.bPurchaseOrSale = bPurchaseOrSale;
      this.bDialogNewBasePrice = !this.bDialogNewBasePrice;
    },
    getBasePriceById() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/price-record/purchase`,
        config,
        payload
      )
        .then((response) => {
          this.oBasePrice = response.data.results;
          this.iCurrentAmount = response.data.results.dAverange;
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    formatMillon(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    refreshTable: function () {
      this.getBasePriceById();
    },
  },
};
</script>
<style scoped>
.content-base-price-raw-po {
  display: flex;
  width: 100%;
  height: 250px;
  margin-bottom: 15px;
  margin-right: 20px;
}

.content-base-price-raw-so {
  display: flex;
  width: 100%;
  height: 250px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.p-text-title-hide {
  color: transparent;
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.p-title {
  color: var(--primary-color-text-title-detail);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: start;
}

.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 35px;
  text-align: start;
  margin-bottom: 0px;
}

.content-title-text-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.content-btns-actions {
  display: flex;
  justify-content: center;
  align-self: center;

  margin-bottom: 0px;
}

.content-btn-close {
  width: 200px;
  margin-right: 15px;
}

.content-btn-new-base-price {
  width: 170px;
}

.content-base-price-po-so {
  display: flex;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-base-price-po-so {
    display: block;
  }

  .content-base-price-raw-po {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0px;
  }

  .content-base-price-raw-so {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .p-text-title {
    text-align: center;
  }

  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
  }

  .content-btn-new-base-price {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-base-price-raw-po {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }

  .content-base-price-raw-so {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }

  .p-text-title {
    /* text-align: center; */
  }

  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
  }

  .content-btn-new-base-price {
    width: 100%;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
